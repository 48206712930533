import { QueryClient, QueryClientProvider } from 'react-query'
import { Helmet } from 'react-helmet'
import React from 'react'

import { ThemeProvider as StyledThemeProvider } from 'styled-components'
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'

import theme from '../../src/theme'

const queryClient = new QueryClient()
const meta = {
	name: 'expirenza by mono',
	description: 'Система менеджменту бронювань та лояльності до гостей',
	image: 'https://takeuseat.in.ua/static/favicon.svg',
	url: 'https://takeuseat.in.ua',
}

export default function TopLayout(props) {
	return (
		<>
			<Helmet>
				<meta name="viewport" content="initial-scale=1, width=device-width" />

				{/* HTML */}
				<title>{meta.name}</title>
				<meta name="description" content={meta.description} />

				{/* GOOGLE */}
				<meta property="name" content={meta.name} />
				<meta property="description" content={meta.description} />
				<meta property="image" content={meta.image} />

				{/* FACEBOOK */}
				<meta property="og:type" content="website" />
				<meta property="og:title" content={meta.name} />
				<meta property="og:description" content={meta.description} />
				<meta property="og:image" content={meta.image} />
				<meta property="og:url" content={meta.url} />

				{/* TWITTER */}
				<meta name="twitter:title" content={meta.name} />
				<meta name="twitter:description" content={meta.description} />
				<meta name="twitter:card" content={meta.image} />
				<meta name="twitter:image" content={meta.image} />

				{/* GOOGLE ANALYTICS */}
				<script async src="https://www.googletagmanager.com/gtag/js?id=G-E0JLHZCLRL" />
				<script>
					{`
						window.dataLayer = window.dataLayer || [];
						function gtag(){dataLayer.push(arguments);}
						gtag('js', new Date());
						gtag('config', 'G-E0JLHZCLRL');
					`}
				</script>

				{/* Facebook Pixel Code */}
				<script>
					{`
							!function(f,b,e,v,n,t,s)
							{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
							n.callMethod.apply(n,arguments):n.queue.push(arguments)};
							if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
							n.queue=[];t=b.createElement(e);t.async=!0;
							t.src=v;s=b.getElementsByTagName(e)[0];
							s.parentNode.insertBefore(t,s)}(window, document,'script',
							'https://connect.facebook.net/en_US/fbevents.js');
							fbq('init', '885343545728046');
							fbq('track', 'PageView');
					`}
				</script>

				{/* Hotjar Tracking Code for https://takeuseat.in.ua */}
				<script>
					{`
							(function(h,o,t,j,a,r){
							h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
							h._hjSettings={hjid:2660758,hjsv:6};
							a=o.getElementsByTagName('head')[0];
							r=o.createElement('script');r.async=1;
							r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
							a.appendChild(r);
							})(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
					`}
				</script>

				<noscript>
					{`<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=885343545728046&ev=PageView&noscript=1" />`}
				</noscript>

				<noscript>
					{`
						<style>
							[data-sal|='fade'] {
								opacity: 1;
							}
							
							[data-sal|='slide'],
							[data-sal|='zoom'] {
								opacity: 1;
								transform: none;
							}

							[data-sal|='flip'] {
								transform: none;
							}
						</style>
					`}
				</noscript>
			</Helmet>
			<QueryClientProvider client={queryClient}>
				<ThemeProvider theme={theme}>
					<StyledThemeProvider theme={theme}>
						<StyledEngineProvider injectFirst>
							<CssBaseline />
							{props.children}
						</StyledEngineProvider>
					</StyledThemeProvider>
				</ThemeProvider>
			</QueryClientProvider>
		</>
	)
}
