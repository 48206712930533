import { Shadows } from '@mui/material/styles/shadows'

export const shadows: Shadows = [
	'none',
	'0px 10px 20px rgba(35, 77, 160, 0.1)',
	'0px 10px 20px rgba(35, 77, 160, 0.1)',
	'0px 10px 20px rgba(35, 77, 160, 0.1)',
	'0px 10px 20px rgba(35, 77, 160, 0.1)',
	'0px 10px 20px rgba(35, 77, 160, 0.1)',
	'0px 10px 20px rgba(35, 77, 160, 0.1)',
	'0px 10px 20px rgba(35, 77, 160, 0.1)',
	'0px 10px 20px rgba(35, 77, 160, 0.1)',
	'0px 10px 20px rgba(35, 77, 160, 0.1)',
	'0px 10px 20px rgba(35, 77, 160, 0.1)',
	'0px 10px 20px rgba(35, 77, 160, 0.1)',
	'0px 10px 20px rgba(35, 77, 160, 0.1)',
	'0px 10px 20px rgba(35, 77, 160, 0.1)',
	'0px 10px 20px rgba(35, 77, 160, 0.1)',
	'0px 10px 20px rgba(35, 77, 160, 0.1)',
	'0px 10px 20px rgba(35, 77, 160, 0.1)',
	'0px 10px 20px rgba(35, 77, 160, 0.1)',
	'0px 10px 20px rgba(35, 77, 160, 0.1)',
	'0px 10px 20px rgba(35, 77, 160, 0.1)',
	'0px 10px 20px rgba(35, 77, 160, 0.1)',
	'0px 10px 20px rgba(35, 77, 160, 0.1)',
	'0px 10px 20px rgba(35, 77, 160, 0.1)',
	'0px 10px 20px rgba(35, 77, 160, 0.1)',
	'0px 10px 20px rgba(35, 77, 160, 0.1)',
]
