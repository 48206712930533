import { createTheme, responsiveFontSizes } from '@mui/material/styles'

import { shadows } from './shadows'
import './fonts.css'

// that are not part of the palette
const colors = {
	primary: '#5F5FD9',
	strokes: '#D3D9E5',
	elements: '#ACB4BF',
	white: '#FFFFFF',
	input: { background: '#F7F8FA', placeholder: '#939AA7', focus: '#88BFFF' },
	skeleton: '#ECEEF2',
	skeletonColor: '#ECEEF2',
}

// A custom theme for this app
const theme = createTheme({
	palette: {
		primary: { main: '#5F5FD9', contrastText: '#fff', light: '#F2F2FF' },
		secondary: { main: '#DEDEFF', contrastText: '#5F5FD9' },
		text: { primary: '#2C2F35', secondary: '#6C7382', disabled: '#ACB3BF' },

		success: { main: '#47BC92', contrastText: '#097757', light: '#D6F4E2' },
		warning: { main: '#E7BC69', contrastText: '#BF8329', light: '#FFF8DD' },
		error: { main: '#FF878E', contrastText: '#EA404A', light: '#FFE1E1' },
		info: { main: '#88BFFF', contrastText: '#2196f3', light: '#E8F4FD' },

		background: { default: '#F7F8FA', paper: '#ffffff' },
		tonalOffset: { light: 0.1, dark: 0.1 },

		divider: '#D3D9E5',
	},
	breakpoints: { values: { xs: 0, sm: 660, md: 960, lg: 1280, xl: 1920 } },
	components: {
		MuiCssBaseline: {
			styleOverrides: {
				'*': { boxSizing: 'border-box' },
				html: {
					MozOsxFontSmoothing: 'grayscale',
					WebkitFontSmoothing: 'antialiased',
					height: '100%',
					width: '100%',
				},
				section: {
					maxWidth: '2080px',
					margin: '0 auto',
					padding: '2rem',
					'@media(max-width: 960px)': {
						padding: '1.5rem',
					},
				},
				'.primary': {
					color: colors.primary,
				},
			},
		},
		MuiAppBar: {
			styleOverrides: {
				root: { background: '#FDFDFE', position: 'sticky', borderRadius: 0, top: 0 },
			},
		},
		MuiButton: {
			defaultProps: { variant: 'contained' },
			styleOverrides: {
				root: { borderRadius: '10px', padding: '8px 24px' },
				outlined: {
					color: '#2C2F35',
					border: `1px solid ${colors.strokes}`,
					background: '#fff',
					'&:hover': { border: `1px solid ${colors.strokes}`, background: '#fff' },
				},
				text: {
					color: '#2C2F35',
					padding: '8px 10px',
					'&': { fontSize: '16px' },
					'&:hover': { background: 'transparent' },
				},
			},
		},
		MuiPaper: {
			styleOverrides: { root: { borderRadius: '16px', border: `1px solid ${colors.strokes}` } },
		},
		MuiAlert: {
			defaultProps: { variant: 'outlined', severity: 'info' },
			styleOverrides: {
				root: { borderRadius: '10px' },
				outlinedInfo: {
					color: '#2C2F35',
					border: `1px solid ${colors.strokes}`,
					'.MuiAlert-icon': { color: colors.elements },
				},
				filledError: { color: '#EA404A', border: `1px solid #EA404A` },
			},
		},
		MuiTextField: { defaultProps: { variant: 'filled' } },
		MuiFilledInput: {
			styleOverrides: {
				root: {
					'&&&:before': { borderBottom: 'none' },
					'&&:after': { borderBottom: 'none' },
					backgroundColor: colors.input.background,
					border: `1px solid ${colors.strokes}`,
					borderRadius: '4px',
					'&:hover': {
						borderColor: colors.input.focus,
						backgroundColor: colors.input.background,
					},
					'&.Mui-focused': {
						borderColor: colors.input.focus,
						backgroundColor: colors.input.background,
					},
				},
				input: {
					'&::placeholder': { color: colors.input.placeholder, opacity: 1 },
					padding: '9px 12px',
				},
			},
		},
		MuiAccordion: {
			defaultProps: { square: true } /* to overwrite borderRadius with custom values below */,
			styleOverrides: {
				root: { borderRadius: '10px', '&:before': { opacity: 0 }, padding: '0rem 1rem' },
			},
		},
		MuiAccordionSummary: { styleOverrides: { root: { borderRadius: '10px' } } },
		MuiAccordionDetails: { styleOverrides: { root: { marginTop: '-1rem', borderRadius: '10px' } } },
		MuiTypography: {
			styleOverrides: {
				h1: { whiteSpace: 'pre-wrap' },
				h2: { whiteSpace: 'pre-wrap' },
				h3: { whiteSpace: 'pre-wrap' },
			},
		},
		MuiSelect: {
			styleOverrides: {
				outlined: {
					padding: '0.65rem 2.5rem 0.65rem 1rem',
					backgroundColor: colors.input.background,
				},
			},
		},
		MuiMenuItem: {
			styleOverrides: {
				root: {
					'&.Mui-selected': {
						'&&': {
							backgroundColor: colors.skeleton,
							'&:hover': { backgroundColor: colors.skeletonColor },
						},
					},
					'&.Mui-focused': { background: colors.skeletonColor },
				},
			},
		},
		MuiInputLabel: {
			defaultProps: { sx: { color: 'text.primary', fontWeight: '600', mb: 1 } },
			styleOverrides: {
				root: {
					position: 'relative',
					pointerEvents: 'auto',
					transform: 'none',
				},
			},
		},
		MuiOutlinedInput: {
			styleOverrides: {
				notchedOutline: {
					top: 0,
					legend: { display: 'none' },
					transition: 'border 200ms ease',
					border: `1px solid ${colors.strokes}`,
				},
				root: {
					'&:not(.Mui-disabled)': {
						'&:hover, &.Mui-focused': {
							'.MuiOutlinedInput-notchedOutline': {
								border: `1px solid ${colors.strokes}`,
							},
						},
					},
					'&.Mui-disabled': {
						'.MuiOutlinedInput-notchedOutline': {
							border: `1px solid ${colors.strokes}`,
						},
					},
				},
				input: {
					'&::placeholder': { color: colors.strokes, opacity: 1 },
					padding: '0.625rem 0.825rem',
				},
			},
		},
	},
	typography: {
		fontFamily:
			'ManropeVariable, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif',
		h1: {
			fontFamily:
				'YesevaOneRegular, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif',
			fontSize: '44px',
			lineHeight: 1.155,
		},
		h2: { fontWeight: 700, fontSize: '28px' },
		h3: { fontWeight: 600, fontSize: '24px' },
		h4: { fontWeight: 600, fontSize: '16px' },
		subtitle1: { fontSize: '18px', lineHeight: 1.36 },
		subtitle2: { fontSize: '12px' },
		body1: { fontWeight: 400, fontSize: '16px' },
		body2: { fontWeight: 400, fontSize: '14px' },
		button: { fontWeight: 600, fontSize: '20px', textTransform: 'none', lineHeight: 1.35 },
		fontWeightRegular: 400,
		fontSize: 16,
	},
	shadows,
	...{ colors },
})

export default responsiveFontSizes(theme)
